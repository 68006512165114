<template>
  <p-container>
    <template slot="left" v-if="showLeft">
      <a-tree
        :show-line="true"
        :default-expanded-keys="['0-0']"
        :tree-data="treeData"
      ></a-tree>
    </template>

    <template slot="toolbar">
      <a-button type="primary" @click="handleEdit">编辑</a-button>
      <a-button type="primary">Excel导出</a-button>
      <a-button type="primary">批量导入</a-button>
      <a-button type="primary">批量分类梳理</a-button>
      <a-button type="primary">申请号批量查询</a-button>
      <a-button type="primary">启动评审</a-button>
    </template>

    <template slot="searchbar">
      <a-form layout="inline">
        <a-form-item label="法律状态">
          <a-select placeholder="法律状态" style="width: 120px"> </a-select>
        </a-form-item>
        <a-form-item label="应用阶段">
          <a-select placeholder="应用阶段" style="width: 120px"> </a-select>
        </a-form-item>
        <a-form-item label="重要性">
          <a-select placeholder="重要性" style="width: 120px"> </a-select>
        </a-form-item>
        <a-form-item label="所属单位">
          <a-select placeholder="所属单位" style="width: 120px"> </a-select>
        </a-form-item>
        <a-form-item label="填写状态">
          <a-select placeholder="填写状态" style="width: 120px"> </a-select>
        </a-form-item>
        <a-form-item>
          <a-select style="width: 120px"> </a-select>
        </a-form-item>
        <a-form-item>
          <a-input style="width: 120px" />
        </a-form-item>

        <a-form-item>
          <a-button type="primary" html-type="submit">
            <a-icon type="search" />检索
          </a-button>
        </a-form-item>
      </a-form>
    </template>

    <a-table
      bordered
      :data-source="dataSource"
      :columns="columns"
      size="middle"
      :row-selection="rowSelection"
      :loading="loading"
    >
      <a
        href="javascript:;"
        @click.prevent="handleToDetail(col)"
        slot="link"
        slot-scope="text, col"
        >{{ text }}</a
      >
    </a-table>
  </p-container>
</template>

<script>
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};
const subjectTreeData = [
  {
    title: "全部",
    key: "0-0",
    children: [
      {
        title: "电气工程",
        key: "0-0-0",
        children: [{ title: "leaf", key: "0-0-0-0" }],
      },
      {
        title: "电子信息与通信技术",
        key: "0-0-1",
        children: [{ title: "leaf", key: "0-0-1-0" }],
      },
      ,
      {
        title: "能源技术",
        key: "0-0-2",
        children: [{ title: "leaf", key: "0-0-2-0" }],
      },
    ],
  },
];
const deviceTreeData = [
  {
    title: "全部",
    key: "0-0",
    children: [
      {
        title: "发电",
        key: "0-0-0",
        children: [{ title: "leaf", key: "0-0-0-0" }],
      },
      {
        title: "输电",
        key: "0-0-1",
        children: [{ title: "leaf", key: "0-0-1-0" }],
      },
      {
        title: "交流、直流换流站、智能变电站、地下变电站等",
        key: "0-0-2",
        children: [{ title: "leaf", key: "0-0-2-0" }],
      },
    ],
  },
];
const columns = [
  {
    title: "专利名称",
    dataIndex: "name",
    width: "30%",
    scopedSlots: { customRender: "link" },
  },
  {
    title: "申请号",
    dataIndex: "age",
  },
  {
    title: "申请人",
    dataIndex: "address",
  },
];
export default {
  data() {
    return {
      loading: false,
      treeData: [],
      rowSelection,
      dataSource: [
        {
          key: "0",
          name: "养生型合成石及其制品",
          age: "201210499081.5",
          address: "中国电力科学研究院",
        },
      ],
      columns,
    };
  },
  computed: {
      type(){
          return this.$route.name.substr(this.$route.name.lastIndexOf("_")+1);
      },
      showLeft(){
        return this.type!='all'
      }
  },
  watch: {
    $route(to, from) {
      this.initPage();
    },
  },
  created() {
    this.initPage();
  },
  methods: {
    initPage() {
      //tree
      switch (this.type) {
        case "subject":
          this.treeData = subjectTreeData;
          break;
        case "device":
          this.treeData = deviceTreeData;
          break;
        default:
          break;
      }
      //clear searchbar

      //loadList
    },
    getList() {
      console.log("数据刷新了");
    },
    // toolbar related
    handleEdit() {},
    // link to detail
    handleToDetail(col) {
      this.$router.push({name: 'ps_manage_detail', params: {type: this.type, id: col.key}});
    }
  }
};
</script>

<style>
</style>